import React, { useContext, useEffect, useState } from "react";
import CustomFont from "../CustomFont";
import DataContext from "../../config/dataContext";
import WorksListPreview from "../WorksListPreview";
import "./relatedWorks.scss";

const RelatedWorks = ({
  work,
  handleHighlightWorkID,
  resetHighlightWorkID,
}) => {
  const data = useContext(DataContext)
  const [extraWorks, setExtraWork] = useState([])
  const [uniqExtraWorks, setUniqExtraWork] = useState([])

  const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

  useEffect(() => {
    setUniqExtraWork(uniqueArray(extraWorks))
  }, [extraWorks])

  useEffect(() => {
    setExtraWork([])
    if (work.tag.order != 1) {
      work.linked_works.map((linkedWork) => {
        let exhibition = data.works.find((work) => work.id === linkedWork.related_work_id)
        exhibition.linked_works.map((linkedWork) => {
          let element = data.works.find((work) => work.id === linkedWork.related_work_id)
          if (element.id !== work.id) {
            setExtraWork(extraWorks => [...extraWorks, {related_work_id: element.id, year: element.year}])
          }
        })
      })
    }
  }, [work])

  return (
    <div className="related--works">
      <div className="label--infos">
        <CustomFont
          tag="p"
          className="small__font--mono t--UPPS c--GREY"
          content={
            data.tags.find((tag) => tag.id === work.tag.id).order === 1
              ? `Individual works (${work.linked_works.length})`
              : work.linked_works.length >= 2 ? `Related exhibitions (${work.linked_works.length})` : "Related exhibition"
          }
        />
      </div>
      <WorksListPreview
        works={work.linked_works}
        handleHighlightWorkID={handleHighlightWorkID}
        resetHighlightWorkID={resetHighlightWorkID}
      />
      {uniqExtraWorks.length >= 1 &&
        <>
          <div className="label--infos">
            <CustomFont
              tag="p"
              className="small__font--mono t--UPPS c--GREY"
              content={uniqExtraWorks.length >= 2
              ? `Related works (${uniqExtraWorks.length})`
              : "Related works"}
            />
          </div>
          <WorksListPreview
            works={uniqExtraWorks}
            handleHighlightWorkID={handleHighlightWorkID}
            resetHighlightWorkID={resetHighlightWorkID}
          />
        </>
      }
    </div>
  );
};

export default RelatedWorks;
