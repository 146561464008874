import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DataContext from '../../config/dataContext';
// import { normalizeQuery } from '../../config/normalize';
// import CustomFont from '../../components/CustomFont';
import FilterTags from '../../components/FilterTags';
import FilterTHead from '../../components/FilterTHead';
import WorksToDisplay from '../../components/WorksToDisplay';
import './works.scss';

const Works = ({ works, tags }) => {
  const data = useContext(DataContext);
  // const isFilterMultiple = true;
  // const { queryParams } = useParams();
  const { sortBy } = useParams();
  const { typeID } = useParams();
  const history = useHistory();
  // const prevTagsQuery = "&tags="
  // const prevTHeadQuery = "&filter="
  const [filterTags, setFilterTags] = useState([]);
  // const [activeTagIDs, setActiveTagIDs] = useState([]);
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [filterTHead, setFilterTHead] = useState([
    {name: "year", isActive: false},
    {name: "type", isActive: true},
    {name: "title", isActive: false},
  ])

  useEffect(() => {
    if (data.isAllDataLoaded === true) {
      setFilterTags(tags.map((tag) => {return {...tag, isActive: false}}))
      if (typeID != '*' && sortBy == 'title') {
        setWorksToDisplay(sortedWorks(works)
          .filter((work) => work.tag.order == typeID)
          .sort((a, b) => a.number - b.number)
          .sort((a, b) => a.title.localeCompare(b.title))
        )
      } else if (typeID != '*' && sortBy == 'year') {
        setWorksToDisplay(sortedWorks(works)
          .filter((work) => work.tag.order == typeID)
          .sort((a, b) => b.year - a.year)
        )
      } else if (typeID != '*' && sortBy == 'type') {
        setWorksToDisplay(sortedWorks(works)
          .filter((work) => work.tag.order == typeID)
        )
      } else if (typeID == '*' && sortBy == 'title') {
        setWorksToDisplay(sortedWorks(works)
          .sort((a, b) => a.number - b.number)
          .sort((a, b) => a.title.localeCompare(b.title))
        )
      } else if (typeID == '*' && sortBy == 'year') {
        setWorksToDisplay(sortedWorks(works)
          .sort((a, b) => b.year - a.year)
        )
      } else {
        setWorksToDisplay(sortedWorks(works))
      }
      setFilterTHead(filterTHead.map((thead) => {
        if (thead.name == sortBy) {
          return {...thead, isActive: true}
        } else return {...thead, isActive: false}
      }))
    }
  }, [data.isAllDataLoaded, typeID, sortBy])

  const handleTagURL = (id) => {
    if (id == typeID) {
      history.push(`/index&type=*&sortBy=${sortBy}`);
    } else {
      history.push(`/index&type=${id}&sortBy=${sortBy}`);
    }
  }

  const handleSortURL = (str) => {
    history.push(`/index&type=${typeID != '*' ? typeID : '*'}&sortBy=${str}`);
  };

  const sortedWorks = (works) => {
    return works
      .sort((a, b) => a.number - b.number)
      .sort((a, b) => b.year - a.year)
      .sort((a, b) => a.tag?.order - b.tag?.order)
  }

  // const handleSortBy = (str) => {
  //   console.log(str)
  //   if (sortBy === "year") {

  //   } else if (sortBy === "type") {

  //   } else {
  //     sortByTitle()
  //   }
  // }

  // const sortByTitle = () => {
  //   let works = worksToDisplay
  //   works
  //     .sort((a, b) => a.title.localeCompare(b.title))
  //     setWorksToDisplay(works)
  // }

  // useEffect(() => {
  //   if (data.isAllDataLoaded === true) {
  //     if (queryParams != undefined) {
  //       let normalizedTagsArr = queryParams.split('=')[1].split('+')
  //       setFilterTags(tags.map((tag) => {
  //         if (normalizedTagsArr.includes(normalizeQuery(tag.name)) === true) {
  //           return {...tag, isActive: true}
  //         } else {
  //           return {...tag, isActive: false}
  //         }
  //       }))
  //       let activesID = [];
  //       filterTags.filter((tag => {
  //         if (tag.isActive === true) {
  //           activesID.push(tag.id)
  //         }
  //       }))
  //       setActiveTagIDs(activesID)
  //     } else {
  //       setFilterTags(tags.map((tag) => {return {...tag, isActive: false}}))
  //       setWorksToDisplay(works)
  //     }
  //   }
  // }, [data.isAllDataLoaded, queryParams])

  // useEffect(() => {
  //   let filteredWorks = []
  //   if (activeTagIDs.length >= 1) {
  //     activeTagIDs.map((id) => {
  //       works.map((work) => {
  //         if (work.tag?.id === id) {
  //           filteredWorks.push(work)
  //         }
  //       })
  //     })
  //   }
  //   setWorksToDisplay(filteredWorks)
  // }, [activeTagIDs])

  // À CHANGER
  // useEffect(() => {
  //   let arrFilterTag = []
  //   filterTags
  //     .filter((tag) => tag.isActive === true)
  //     .map((filteredTag) => {
  //       arrFilterTag.push(normalizeQuery(filteredTag.name))
  //     })
  //   if (arrFilterTag.length >= 1) {
  //     history.push(`/index${prevTagsQuery}${arrFilterTag.join('+')}`)
  //   }
  //   else {
  //     history.push('/index')
  //   }
  // }, [filterTags])

  // const handleFilterTags = (clickedTag) => {
  //   setFilterTags(
  //     filterTags.map((tag) => {
  //       if (clickedTag.id === tag.id) {
  //         if (clickedTag.isActive === true) {
  //           return { ...clickedTag, isActive: false };
  //         } else {
  //           return { ...clickedTag, isActive: true };
  //         }
  //       } else {
  //         if (isFilterMultiple === true) {
  //           return { ...tag };
  //         } else {
  //           return { ...tag, isActive: false };
  //         }
  //       }
  //     })
  //   );
  // };


  // useEffect(() => {
  //   let activeTHead = filterTHead.find((thead) => thead.isActive === true)
  //   if (activeTHead.name === "year") {
  //     handleSortByYear()
  //   } else if (activeTHead.name === "type") {
  //     handleSortByTag()
  //   } else if (activeTHead.name === "title") {
  //     handleSortByTitle()
  //   }
  // }, [filterTHead])

  // const handleSortByYear = () => {
  //   console.log('sort by year')
  //   // setWorksToDisplay(worksToDisplay.sort((a, b) => b.year - a.year))
  // }

  // const handleSortByTag = () => {
  //   console.log('sort by tag')
  // }

  // const handleSortByTitle = () => {
  //   console.log('sort by title')
  //   // setWorksToDisplay(worksToDisplay.sort((a, b) => a.title.localeCompare(b.title)))
  // }

  return (
    <div className="wrapper__index">
      <Helmet>
        <title>{data.artistName} - Index</title>
      </Helmet>
      <div className="container__index">
        <div className="container__fixed--top" id="STICKY-FILTER">
          <FilterTags tags={filterTags} handleTagURL={handleTagURL} typeID={typeID} />
          {data.isAllDataLoaded && <FilterTHead data={filterTHead} handleSortURL={handleSortURL} />}
          {/* <FilterTags tags={filterTags} handleFilterTags={handleFilterTags}/>
          {data.isAllDataLoaded && <FilterTHead data={filterTHead} />} */}
        </div>
        <WorksToDisplay works={worksToDisplay}/>
      </div>
    </div>
  );
};

export default Works;