import React, { useContext } from 'react';
import DataContext from "../../config/dataContext";
import LabelContent from "../LabelContent";
import './contactCopyright.scss';

const ContactCopyright = () => {
  const data = useContext(DataContext);
  const year = new Date().getFullYear();
  const contactLinks = [
    {label: "mail", link: "mailto:" + data.informations[0]?.mail, text: data.informations[0]?.mail},
    {label: "instagram", link: data.informations[0]?.instagram, text: "@rubyskystiler"}
  ];

  return (
    <div className="container__contact--copyright">
      <ul className="container--left">
        {contactLinks.map((link, i) =>
          <li key={i}>
            <LabelContent
              label={link.label}
              link={link.link}
              text={link.text}
            />
          </li>
        )}
      </ul>
      <div className="container--right">
        <LabelContent
          label="copyright"
          content={`All rights reserved © Ruby Sky Stiler ${year}`}
        />
      </div>
    </div>
  );
};

export default ContactCopyright;