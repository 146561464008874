import React from "react";
import "./logo.scss";

const Logo = () => {
  return (
    <div className="container__logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 983.95 83.6"
        className="logo--svg"
      >
        <g id="RUBY">
          <path d="M0,1.09H35.29c13.35,0,20.63,6.51,20.63,20.62v3.48c0,10.31-3.91,15.74-11.19,17.26v.87c7.82,1.85,10.86,8.14,10.86,19.44a151.27,151.27,0,0,0,1.19,19.76H44.19c-.65-4-.87-14.34-.87-20.31,0-10-3.36-13-12.59-13H12.49V82.41H0Zm30.62,38c9.23,0,12.59-2.28,12.59-12.49V24.1c0-10-3.15-12.59-11.83-12.59H12.49V39.09Z" />
          <path d="M66.12,58.09v-57h12.7V57c0,11.18,4.13,15.53,14.88,15.53S108.46,68.18,108.46,57V1.09h12.7v57c0,17.37-9.22,25.51-27.46,25.51C75.24,83.6,66.12,75.46,66.12,58.09Z" />
          <path d="M133,1.09h34c12.92,0,19.87,7.92,19.87,20.73,0,9.23-3.48,15.86-9.45,16.94v.76c7.28,1.3,11.84,8.58,11.84,20.3,0,15.1-7.82,22.7-21.83,22.7H133Zm28.56,35c9.34,0,12.59-2.93,12.59-12.49S171,11.08,161.66,11.08H145.48v25Zm2.17,36.48c9.45,0,12.6-3.26,12.6-13.57s-3-13.58-12.49-13.58H145.48V72.53Z" />
          <path d="M220.18,48.86,193.25,1.09H207.8L226,36.26h.87L245,1.09h14.33L232.55,48.86V82.52H220.18Z" />
        </g>
        <g id="SKY">
          <path d="M328.74,56.68V56h13v.11C342,68.4,347,73.07,358.82,73.07c11.4,0,16.72-4.13,16.72-13.25,0-6.84-3.26-9.88-13-12.37L349.7,44.19c-12.06-3.15-18.35-10-18.35-21.5v-.32C331.35,8.14,341.12,0,358.27,0c17.81,0,27.8,9,27.69,25.08v.54h-12.7v-.21c0-10.21-4.89-14.88-14.88-14.88-9.34,0-14.33,3.69-14.33,11.29,0,6.63,3.91,9.77,11.62,11.62l13.9,3.37c12.7,3,18.78,9.88,18.78,22.25v.22c0,16-10.21,24.32-29.75,24.32C338.51,83.6,328.63,74.81,328.74,56.68Z" />
          <path d="M413.75,47.12h-4.12v35.4H397.14V1.09h12.49V36.91h4.45l25.4-35.82H454l-30,40.71L456,82.52H440.57Z" />
          <path d="M485.08,48.86,458.15,1.09H472.7l18.24,35.17h.87L509.94,1.09h14.33L497.46,48.86V82.52H485.08Z" />
        </g>
        <g id="STILER">
          <path d="M593.65,56.68V56h13v.11c.22,12.27,5.22,16.94,17.05,16.94,11.4,0,16.72-4.13,16.72-13.25,0-6.84-3.26-9.88-13-12.37L614.6,44.19c-12.05-3.15-18.35-10-18.35-21.5v-.32C596.25,8.14,606,0,623.18,0,641,0,651,9,650.86,25.08v.54h-12.7v-.21c0-10.21-4.89-14.88-14.87-14.88C614,10.53,609,14.22,609,21.82c0,6.63,3.9,9.77,11.61,11.62l13.9,3.37c12.7,3,18.78,9.88,18.78,22.25v.22c0,16-10.2,24.32-29.75,24.32C603.42,83.6,593.54,74.81,593.65,56.68Z" />
          <path d="M683.43,12.05H660v-11h59.61v11H696.14V82.52H683.43Z" />
          <path d="M729.24,71.55h20.31V12.05H729.24v-11h53.42v11H762.47v59.5h20.3v11H729.24Z" />
          <path d="M796.56,1.09h12.81V71.55h41.15v11h-54Z" />
          <path d="M862.78,1.09h52.77v11H875.27V36.16h36.59V46.8H875.27V71.55h41.15v11H862.78Z" />
          <path d="M927.16,1.09h35.29c13.36,0,20.63,6.51,20.63,20.62v3.48c0,10.31-3.91,15.74-11.18,17.26v.87c7.81,1.85,10.85,8.14,10.85,19.44A151,151,0,0,0,984,82.52h-12.6c-.65-4-.87-14.34-.87-20.31,0-10-3.36-13-12.59-13H939.65V82.41H927.16Zm30.62,38c9.23,0,12.59-2.28,12.59-12.49V24.1c0-10-3.14-12.59-11.83-12.59H939.65V39.09Z" />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 390.35 287.97"
        className="logo--svg is--big"
      >
        <g id="RUBY">
          <path
            d="M0,0H35.29C48.64,0,55.92,6.51,55.92,20.62V24.1c0,10.31-3.91,15.74-11.19,17.26v.87c7.82,1.85,10.86,8.14,10.86,19.44a150,150,0,0,0,1.19,19.76H44.19c-.65-4-.87-14.34-.87-20.31,0-10-3.36-13-12.59-13H12.49v33.2H0ZM30.62,38c9.23,0,12.59-2.28,12.59-12.49V23c0-10-3.15-12.59-11.83-12.59H12.49V38Z"
          />
          <path
            d="M66.12,57V0h12.7V55.91c0,11.18,4.13,15.53,14.88,15.53s14.76-4.35,14.76-15.53V0h12.7V57c0,17.37-9.22,25.51-27.46,25.51C75.24,82.51,66.12,74.37,66.12,57Z"
          />
          <path
            d="M133,0h34c12.92,0,19.87,7.92,19.87,20.73,0,9.23-3.48,15.86-9.45,16.94v.76c7.28,1.3,11.84,8.58,11.84,20.3,0,15.1-7.82,22.7-21.83,22.7H133Zm28.56,35c9.34,0,12.59-2.93,12.59-12.49S171,10,161.66,10H145.48V35Zm2.17,36.48c9.45,0,12.6-3.26,12.6-13.57s-3-13.58-12.49-13.58H145.48V71.44Z"
          />
          <path
            d="M220.18,47.77,193.25,0H207.8L226,35.17h.87L245,0h14.33L232.55,47.77V81.43H220.18Z"
          />
        </g>
        <g id="SKY">
          <path
            d="M0,157.45v-.68H13v.11c.26,12.29,5.26,17,17.08,17,11.4,0,16.72-4.13,16.72-13.25,0-6.84-3.26-9.88-13-12.37L21,145C8.9,141.81,2.61,135,2.61,123.46v-.32c0-14.23,9.77-22.37,26.92-22.37,17.81,0,27.8,9,27.69,25.08v.54H44.52v-.21c0-10.21-4.89-14.88-14.88-14.88-9.34,0-14.33,3.69-14.33,11.29,0,6.63,3.91,9.77,11.62,11.62l13.9,3.37c12.7,3,18.78,9.88,18.78,22.25v.22c0,16-10.21,24.32-29.75,24.32C9.77,184.37-.11,175.58,0,157.45Z"
          />
          <path
            d="M85,147.89H80.89v35.4H68.4V101.86H80.89v35.82h4.45l25.4-35.82h14.52l-30,40.71,32,40.72H111.83Z"
          />
          <path
            d="M156.34,149.63l-26.93-47.77H144L162.2,137h.87l18.13-35.17h14.33l-26.81,47.77v33.66H156.34Z"
          />
        </g>
        <g id="STILER">
          <path
            d="M0,261.05v-.68H13v.11c.22,12.27,5.22,16.94,17.05,16.94,11.4,0,16.72-4.13,16.72-13.25,0-6.84-3.26-9.88-13-12.37L21,248.56c-12-3.15-18.35-10-18.35-21.5v-.32c0-14.23,9.75-22.37,26.93-22.37,17.82,0,27.82,9,27.68,25.08V230H44.51v-.21c0-10.21-4.89-14.88-14.87-14.88-9.29,0-14.29,3.69-14.29,11.29,0,6.63,3.9,9.77,11.61,11.62l13.9,3.37c12.7,3,18.78,9.88,18.78,22.25v.22c0,16-10.2,24.32-29.75,24.32C9.77,288-.11,279.18,0,261.05Z"
          />
          <path
            d="M89.78,216.42H66.35v-11H126v11H102.49v70.47H89.78Z"
          />
          <path
            d="M135.59,275.92H155.9v-59.5H135.59v-11H189v11H168.82v59.5h20.3v11H135.59Z"
          />
          <path
            d="M202.91,205.46h12.81v70.46h41.15v11h-54Z"
          />
          <path
            d="M269.13,205.46H321.9v11H281.62v24.07h36.59v10.64H281.62v24.75h41.15v11H269.13Z"
          />
          <path
            d="M333.51,205.46H368.8c13.36,0,20.63,6.51,20.63,20.62v3.48c0,10.31-3.91,15.74-11.18,17.26v.87c7.81,1.85,10.85,8.14,10.85,19.44a150,150,0,0,0,1.25,19.76h-12.6c-.65-4-.87-14.34-.87-20.31,0-10-3.36-13-12.59-13H346v33.2H333.51Zm30.62,38c9.23,0,12.59-2.28,12.59-12.49v-2.5c0-10-3.14-12.59-11.83-12.59H346v27.58Z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
