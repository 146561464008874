import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import DataContext from '../../config/dataContext';
import { Helmet } from 'react-helmet';
import BioExhibitions from '../../components/BioExhibitions';
import ReactMarkdown from "react-markdown";
import NavbarBiography from '../../components/NavbarBiography';
import './biography.scss';

const Biography = () => {
  const data = useContext(DataContext)
  const navLinks = [
    {name: "education", slug: "education"},
    {name: "solo and two person exhibitions", slug: "solo-and-two-person-exhibitions"},
    {name: "selected group exhibitions", slug: "selected-group-exhibitions"},
    {name: "bibliography", slug: "bibliography"},
    {name: "publications", slug: "publications"},
    {name: "public collection", slug: "public-collection"}
  ]

  return (
    <div className="wrapper__biography">
      <Helmet>
        <title>{data.artistName} - Biography</title>
      </Helmet>
      <div className="container__biography">
        <NavbarBiography navLinks={navLinks}/>
        <div className="wrapper__switch--biography">
          <div className="container__infos--biography">
            <ReactMarkdown
            className="content big__font"
            linkTarget="_blank"
            children="string"
            >
              {data.informations[0]?.description}
            </ReactMarkdown>
          </div>
          <Switch>
            <Route path="/biography/education" render={() => <BioExhibitions data={data.educations} title="Education" />} />
            <Route path="/biography/solo-and-two-person-exhibitions" render={() => <BioExhibitions data={data.soloExhibitions} title="Solo and two person exhibitions" />} />
            <Route path="/biography/selected-group-exhibitions" render={() => <BioExhibitions data={data.groupExhibitions} title="Selected group exhibitions" />} />
            <Route path="/biography/bibliography" render={() => <BioExhibitions data={data.bibliographies} title="Bibliography" />} />
            <Route path="/biography/publications" render={() => <BioExhibitions data={data.publications} title="Publications" />} />
            <Route path="/biography/public-collection" render={() => <BioExhibitions data={data.publicCollections} title="Public collection" />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Biography;
