import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router';
// import { useHistory } from "react-router-dom";
import { normalizeQuery, sortedMediaName } from "../../config/normalize";
import { Helmet } from 'react-helmet';
import DataContext from "../../config/dataContext";
// import CustomFont from "../../components/CustomFont";
import Image from "../../components/Image";
import InformationsWork from "../../components/InformationsWork";
import WorkPreview from "../../components/WorkPreview";
import RelatedWorks from "../../components/RelatedWorks";
import './work.scss';

const Work = ({ works }) => {
  const { queryParams } = useParams();
  const data = useContext(DataContext);
  // const history = useHistory();
  const [work, setWork] = useState({});
  const paramId = parseInt(queryParams.split("=")[0], 10);
  // const paramTitle = queryParams.split("=")[1];
  const [highlightWorkID, setHighlightWorkID] = useState();

  useEffect(() => {
    if (data.isAllDataLoaded === true) {
      let temporaryWork = works.find(work => work.id === paramId)
      setWork(temporaryWork)
      // if (temporaryWork.id === paramId && normalizeQuery(temporaryWork.title) == normalizeQuery(paramTitle)) {
      //   setWork(temporaryWork);
      // } else {
      //   history.push("/error404");
      // }
    }
  }, [data.isAllDataLoaded, queryParams]);

  useEffect(() => {
    resetHighlightWorkID()
  }, [work])

  const handleHighlightWorkID = (id) => {
    setHighlightWorkID(id)
  }

  const resetHighlightWorkID = () => {
    setHighlightWorkID()
  }

  return (
    <div className="wrapper__work">
      <Helmet>
        <title>{`${data.artistName} - ${work.title}`}</title>
      </Helmet>
      <div className="container__work">
        <div className="container--left">
          <div className="container--fixed">
            <InformationsWork
              work={work}
              isCompleted={true}
              handleHighlightWorkID={handleHighlightWorkID}
              resetHighlightWorkID={resetHighlightWorkID}
            />
          </div>
        </div>
        <div className={work.linked_works?.length <= 0 && data.maxWidth < 768 ? "container--right is--padding" : "container--right"}>
          {/* <div className={highlightWorkID ? "label--infos is--ACTIVE" : "label--infos"}>
            <CustomFont
              tag="p"
              className="label small__font--mono t--UPPS c--GREY"
              content="medias"
            />
          </div> */}
          <ul className={highlightWorkID ? "container__medias is--ACTIVE" : "container__medias"}>
            {work.medias && work.medias
              .sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))
              .map((img, i) =>
                <li
                  key={i}
                >
                  <Image
                    // src={img.formats?.large.url}
                    src={img.url}
                    title={work.title}
                    year={work.year}
                  />
                </li>
            )}
          </ul>
          {work.linked_works?.length >= 1 &&
            <RelatedWorks
              work={work}
              handleHighlightWorkID={handleHighlightWorkID}
              resetHighlightWorkID={resetHighlightWorkID}
            />
          }
          {highlightWorkID &&
            <WorkPreview workID={highlightWorkID} />
          }
        </div>
      </div>
    </div>
  )
};

export default Work;