import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from '../Navbar';
import DataContext from "../../config/dataContext";
import CustomFont from '../CustomFont';
import './header.scss';

const Header = ({ isMenuActive, handleMenu, closeMenu }) => {
  const data = useContext(DataContext);

  return (
    <div className="wrapper__header">
      <header className="container__header">
        <NavLink
          to="/"
          className="link"
          exact activeClassName="active"
          onClick={closeMenu}
        >
          <CustomFont
            tag="p"
            className="small__font--mono t--UPPS"
            // content={data.artistName}
            content={"SELECTED EXHIBITIONS"}
          />
        </NavLink>
        <Navbar isMenuActive={isMenuActive} handleMenu={handleMenu} />
        <div
          onClick={handleMenu}
          className={isMenuActive ? "c--GREY cta--menu" : "cta--menu"}
        >
          <CustomFont
            tag="p"
            className="small__font--mono t--UPPS"
            content={isMenuActive ? "close" : "menu"}
          />
        </div>
      </header>
    </div>
  );
};

export default Header;