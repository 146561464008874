import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import { NavLink } from 'react-router-dom';
// import CustomFont from '../CustomFont';
import './navbarBiography.scss';

const NavbarBiography = ({ navLinks}) => {
  const data = useContext(DataContext)

  return (
    <div className="wrapper__navbar--biography">
      <nav className="container__navbar--biography">
        <ul className="nav--items">
          {navLinks.map((link, i) => 
            <li
              key={i}
              className="nav--item"
            >
              <NavLink
                className="link TAG"
                exact activeClassName="active "
                to={`/biography/${link.slug}`}
              >
                <p className="small__font--mono t--UPPS">{link.name}</p>
              </NavLink>
            </li>
          )}
          <li className="nav--item">
            <a
              className="link TAG t--UPPS small__font--mono"
              href={data.informations[0]?.cv.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              download cv
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavbarBiography
