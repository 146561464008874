import React from 'react';
import CustomFont from '../CustomFont';
import { normalizeQuery } from '../../config/normalize';
import './tag.scss';

const Tag = ({ data }) => {

  return (
    <button
      // className="TAG"
      className={data?.order === 1 ? "TAG is--N1" : "TAG"}
      data-filter={data?.name && normalizeQuery(data?.name)}
      data-is-active={data?.isActive}
      data-order={data?.order}
    >
      <CustomFont
        tag={"p"}
        className={"small__font--mono t--UPPS"}
        // content={data?.name ? data.name : "unkown"}
        content={data?.name}
      />
    </button>
  );
};

export default Tag;