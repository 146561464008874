import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import DataContext from '../../config/dataContext';
import LabelContent from '../../components/LabelContent';
import './imprint.scss';

const Imprint = () => {
  const data = useContext(DataContext);
  const year = new Date().getFullYear();

  return (
    <div className="wrapper__imprint">
      <Helmet>
        <title>{data.artistName} - Imprint</title>
      </Helmet>
      <div className="container__imprint">
        <ul className="container--left">
          {data.imprint[0]?.legals.map((legal, i) =>
            <li
              key={i}
            >
              <LabelContent
                label={legal.label}
                content={legal.description}
              />
            </li>
          )}
        </ul>
        <div className="container--right">
          <LabelContent
            label="copyright"
            content={`All rights reserved © Ruby Sky Stiler ${year}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Imprint;
