import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import CustomFont from "../../components/CustomFont";
import ContactCopyright from "../../components/ContactCopyright";
import LabelContent from "../../components/LabelContent"
import './error404.scss';

const Error404 = () => {
  const data = useContext(DataContext)

  return (
    <div className="wrapper__error">
      <Helmet>
        <title>{data.artistName} - Error 404</title>
      </Helmet>
      <div className="container__error">
        <div className="container--top">
          <LabelContent
            label="error 404"
            content="The page you are looking for doesn't exist anymore."
          />
          <Link to="/" className="link">
            <CustomFont
              tag="p"
              className="small__font--mono t--UPPS"
              content="go home"
            />
          </Link>
        </div>
        <ContactCopyright />
      </div>
    </div>
  )
};

export default Error404;