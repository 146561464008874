import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { normalizeQuery, sortedMediaName } from "../../config/normalize";
import DataContext from "../../config/dataContext";
import LabelContent from "../LabelContent";
import CustomFont from "../CustomFont";
import Tag from "../Tag";
import "./workPreview.scss";

const WorkPreview = ({ workID }) => {
  const data = useContext(DataContext);
  const work = data.works.find((work) => workID === work.id)
  const [isExhibition, setIsExhibition] = useState()

  useEffect(() => {
    let workTag = data.tags.find(tag => tag.id === work.tag.id)
    if (workTag.order === 1) {
      setIsExhibition(true)
    } else setIsExhibition(false)
  }, [workID])

  return (
    <div className="container__preview--highlight">
      <div className="container__top--img">
        {/* <div className="label--infos">
          <CustomFont
            tag="p"
            className="label small__font--mono t--UPPS c--GREY"
            content="medias"
          />
        </div> */}
        {isExhibition ?
          <>
            <div className="container__grid--preview">
              <img
                src={work.medias[0]?.formats.large.url}
                // alt={`${data.artistName} - ${work.title}`}
              />
              <img
                src={work.medias[1]?.formats.large.url}
                // alt={`${data.artistName} - ${work.title}`}
              />
              <img
                src={work.medias[2]?.formats.large.url}
                // alt={`${data.artistName} - ${work.title}`}
              />
              <img
                src={work.medias[3]?.formats.large.url}
                // alt={`${data.artistName} - ${work.title}`}
              />
            </div>
            {work.medias.length >= 5 &&
              <div className="count--item">
                <CustomFont
                  tag="p"
                  className="small__font--mono t--UPPS c--GREY"
                  content={`(${work.medias.length - 4} more)`}
                />
              </div>
            }
          </>
        :
          <img
            src={work.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.url}
            //src={work.medias[0]?.formats.medium.url}
            // alt={`${data.artistName} - ${work.title}`}
            className="img--solo"
          />
        }
      </div>
      <div className="container__bottom">
        {isExhibition ?
          work.location &&
            <LabelContent
              label="location"
              content={work.location}
            />
        :
          work.materials &&
            <LabelContent
              label="medium"
              content={work.materials}
            />
        }
        {isExhibition ?
          work.city &&
            <LabelContent
              label="city"
              content={work.city}
            />
        :
          work.dimensions &&
            <LabelContent
              label="dimensions"
              content={`${work.dimensions} inches`}
            />
        }
      </div>
    </div>
  );
};

export default WorkPreview;
