import React from 'react';
import CustomFont from '../CustomFont';
import ReactMarkdown from "react-markdown";
import './labelContent.scss';

const LabelContent = ({ label, content, link, text }) => {

  return (
    <div className="container__label--content md--RULES">
      <div className="label">
        <CustomFont
          tag="p"
          className="small__font--mono t--UPPS c--GREY"
          content={label}
        />
      </div>
      {link != undefined && text != undefined &&
        <a
          href={link}
          target="_blank"
        >
          <CustomFont
            tag="p"
            className="small__font"
            content={text}
          />
        </a>
      }
      {content != undefined &&
        <ReactMarkdown
          className="content small__font"
          linkTarget="_blank"
          children="string"
        >
          {content}
        </ReactMarkdown>
      }
    </div>
  );
};

export default LabelContent;