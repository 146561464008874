import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import CustomFont from '../../components/CustomFont';
import { Helmet } from 'react-helmet';
import ContactCopyright from '../../components/ContactCopyright';
import ReactMarkdown from "react-markdown";
import './contact.scss';

const Contact = ({ galleries }) => {
  const data = useContext(DataContext)

  return (
    <>
    <div className="wrapper__contact">
      <Helmet>
        <title>{data.artistName} - Contact</title>
      </Helmet>
      <div className="container__contact">
        <div className="container__top">
          <div className="label">
            <CustomFont
              tag="p"
              className="small__font--mono t--UPPS c--GREY"
              content="gallery"
            />
          </div>
          <ul className="container__galeries">
            {galleries.map((gallery, i) =>
              <li
                key={i}
                className="gallery--item"
              >
                <CustomFont
                  tag="p"
                  className="small__font"
                  content={gallery.title}
                />
                <ReactMarkdown
                  className="content small__font"
                  linkTarget="_blank"
                  children="string"
                >
                  {gallery.adress}
                </ReactMarkdown>
                <div className="contacts md--RULES">
                  <a href={`mailto:${gallery.email}`} target="_blank">
                    <CustomFont
                      tag="p"
                      className="small__font"
                      content="Mail"
                    />
                  </a>
                  <a href={gallery.website} target="_blank">
                    <CustomFont
                      tag="p"
                      className="small__font"
                      content="Website"
                    />
                  </a>
                  <a href={gallery.instagram} target="_blank">
                    <CustomFont
                      tag="p"
                      className="small__font"
                      content="Instagram"
                    />
                  </a>
                </div>
              </li>
            )}
          </ul>
        </div>
        <ContactCopyright />
      </div>
    </div>
    </>
  );
};

export default Contact;
