import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { motion } from 'framer-motion/dist/framer-motion';
import './progressBar.scss';

const ProgressBar = ({}) => {
  const data = useContext(DataContext)

  return (
    <div className="container__progress--bar">
      <motion.div
        className="progress--bar"
        initial={{
          width: 0
        }}
        animate={{
          width: data.isAllDataLoaded ? '100%' : '75%'
        }}
        transition={{
          ease: "backOut",
          default: { duration: data.isAllDataLoaded ? 1 : 5},
          delay: 0.25
        }}
      ></motion.div>
    </div>
  );
};

export default ProgressBar;
