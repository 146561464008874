import React from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.scss';

const Navbar = ({ isMenuActive, handleMenu }) => {
  const navLinks = [
    {name: "index", slug: "index"},
    {name: "biography", slug: "biography"},
    {name: "press", slug: "press"},
    {name: "search", slug: "search"},
    {name: "contact", slug: "contact"},
    {name: "imprint", slug: "imprint"}
  ]

  return (
    <div className={isMenuActive ? "wrapper__navbar is--menu--active" : "wrapper__navbar"}>
      <nav className="container__navbar">
        <ul className="nav--items">
          {navLinks.map((link, i) => 
            <li
              key={i}
              className="nav--item"
              onClick={handleMenu}
            >
              <NavLink
                className="link"
                activeClassName="active"
                to={`/${link.slug}`}
              >
                <p className="small__font--mono t--UPPS">{link.name}</p>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
