import React from 'react';
import Tag from '../Tag';
import './filterTags.scss';

const FilterTags = ({ tags, handleFilterTags, handleTagURL, typeID }) => {
  const orderedTags = tags.filter((tag) => tag.order != 999).sort((a, b) => a.order - b.order)

  return (
    <ul className="container__filter--tags">
      {orderedTags.map((tag, i) => 
        <li
          key={i}
          className={tag.order == typeID ? "filter--tag is--ACTIVE" : "filter--tag"}
          onClick={() => handleTagURL(tag.order)}
          // onClick={() => handleFilterTags(tag)}
        >
          <Tag data={tag} />
        </li>
      )}
    </ul>
  );
};

export default FilterTags;