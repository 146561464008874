import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { fetchData } from "./config/fetchData";
import DataContext from "./config/dataContext";
import ScrollMemory from 'react-router-scroll-memory';
import StartAnimation from "./components/StartAnimation";
// import { motion } from 'framer-motion/dist/framer-motion';
import Header from "./components/Header";
import Home from "./Pages/Home";
import Works from "./Pages/Works";
import Work from "./Pages/Work";
import Contact from "./Pages/Contact";
import Search from "./Pages/Search";
import Error404 from "./Pages/Error404";
import Biography from "./Pages/Biography";
import Press from "./Pages/Press";
import Imprint from "./Pages/Imprint";
import "./styles/reset.scss";
import "./index.scss";

const App = () => {
  const artistName = "Ruby Sky Stiler"
  const [works, setWorks] = useState([]);
  const [isWorksIsLoaded, setIsWorksLoaded] = useState(false);
  const [tags, setTags] = useState([]);
  const [isTagsLoaded, setIsTagsLoaded] = useState(false);
  const [presses, setPresses] = useState([]);
  const [isPressesLoaded, setIsPressesLoaded] = useState(false);
  const [soloExhibitions, setSoloExhibitions] = useState([]);
  const [isSoloExhibitionsLoaded, setIsSoloExhibitionsLoaded] = useState(false);
  const [groupExhibitions, setGroupExhibitions] = useState([]);
  const [isGroupExhibitionsLoaded, setIsGroupExhibitionsLoaded] = useState(false);
  const [educations, setEducations] = useState([]);
  const [isEducationsLoaded, setIsEducationsLoaded] = useState(false);
  const [bibliographies, setBibliographies] = useState([]);
  const [isBibliographiesLoaded, setIsBibliographiesLoaded] = useState(false);
  const [publications, setPublications] = useState([]);
  const [isPublicationsLoaded, setIsPublicationsLoaded] = useState(false);
  const [publicCollections, setPublicCollections] = useState([]);
  const [isPublicCollectionsLoaded, setIsPublicCollectionsLoaded] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [isGalleriesLoaded, setIsGalleriesLoaded] = useState(false);
  const [months, setMonths] = useState([]);
  const [isMonthsLoaded, setIsMonthsLoaded] = useState(false);
  const [informations, setInformations] = useState([]);
  const [isInformationsLoaded, setIsInformationsLoaded] = useState(false);
  const [imprint, setImprint] = useState([]);
  const [isImprintLoaded, setIsImprintLoaded] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const [maxWidth, setMaxWidth] = useState();
  const [maxHeight, setMaxHeight] = useState();

  window.addEventListener('resize', function(){
    setIsMenuActive(false)
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  });

  useEffect(() => {
    fetchData(setWorks, "works", setIsWorksLoaded);
    fetchData(setTags, "tags", setIsTagsLoaded);
    fetchData(setPresses, "presses", setIsPressesLoaded);
    fetchData(setSoloExhibitions, "solo-exhibitions", setIsSoloExhibitionsLoaded);
    fetchData(setGroupExhibitions, "group-exhibitions", setIsGroupExhibitionsLoaded);
    fetchData(setBibliographies, "bibliographies", setIsBibliographiesLoaded);
    fetchData(setPublications, "publications", setIsPublicationsLoaded);
    fetchData(setPublicCollections, "public-collections", setIsPublicCollectionsLoaded);
    fetchData(setMonths, "months", setIsMonthsLoaded);
    fetchData(setEducations, "educations", setIsEducationsLoaded);
    fetchData(setInformations, "informations", setIsInformationsLoaded);
    fetchData(setImprint, "imprint", setIsImprintLoaded);
    fetchData(setGalleries, "galleries", setIsGalleriesLoaded);
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  }, []);

  useEffect(() => {
    if (
      isWorksIsLoaded === true &&
      isTagsLoaded === true &&
      isPressesLoaded === true &&
      isSoloExhibitionsLoaded === true &&
      isGroupExhibitionsLoaded === true &&
      isBibliographiesLoaded === true &&
      isPublicationsLoaded === true &&
      isPublicCollectionsLoaded === true &&
      isMonthsLoaded === true &&
      isEducationsLoaded === true &&
      isInformationsLoaded == true &&
      isGalleriesLoaded == true &&
      isImprintLoaded
      ) {
        setIsAllDataLoaded(true)
      }
  }, [
    isWorksIsLoaded,
    isTagsLoaded,
    isPressesLoaded,
    isSoloExhibitionsLoaded,
    isGroupExhibitionsLoaded,
    isBibliographiesLoaded,
    isPublicationsLoaded,
    isPublicCollectionsLoaded,
    isMonthsLoaded,
    isEducationsLoaded,
    isInformationsLoaded,
    isGalleriesLoaded,
    isImprintLoaded
  ]);

  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }
  const closeMenu = () => {
    setIsMenuActive(false)
  }

  return (
    <DataContext.Provider
      value={{
        artistName: artistName,
        works: works,
        tags: tags,
        presses: presses,
        soloExhibitions: soloExhibitions,
        groupExhibitions: groupExhibitions,
        bibliographies: bibliographies,
        publications: publications,
        publicCollections: publicCollections,
        months: months,
        educations: educations,
        galleries: galleries,
        informations: informations,
        imprint: imprint,
        isAllDataLoaded: isAllDataLoaded,
        isMenuActive: isMenuActive,
        setIsMenuActive: setIsMenuActive,
        closeMenu: closeMenu,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      }}
    >
      <Router>
        <div id="SCROLL__RESTORATION">
          <ScrollMemory />
            <div className="wrapper__application">
              <StartAnimation />
              <div className="container__application">
                <Header isMenuActive={isMenuActive} handleMenu={handleMenu} closeMenu={closeMenu} />
                <div className="wrapper__switch--main">
                  <Switch>
                    <Route path="/" exact render={() => <Home works={works} />} />
                    <Route path="/index" render={() => <Redirect to="/index&type=*&sortBy=type" />} />
                    <Route path="/index&type=:typeID&sortBy=:sortBy" render={() => <Works works={works} tags={tags} />} />
                    <Route path="/index:queryParams" render={() => <Works works={works} tags={tags} />} />
                    <Route path="/work/:queryParams" render={() => <Work works={works} />} />
                    {/* <Route path="/biography" render={() => <Redirect to="/biography/education" />} /> */}
                    <Route path="/biography" render={() => <Biography />} />
                    <Route path="/press" render={() => <Press presses={presses} />} />
                    <Route path="/search" exact render={() => <Search works={works} />} />
                    <Route path="/search:queryParams" render={() => <Search works={works} />} />
                    <Route path="/contact" render={() => <Contact galleries={galleries} />} />
                    <Route path="/imprint" render={() => <Imprint />} />
                    <Route path="*" render={() => <Error404 />} />
                  </Switch>
                </div>
              </div>
            </div>
        </div>
      </Router>
    </DataContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
