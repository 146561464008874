import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import './image.scss';

const Image = ({ src, title, year, className }) => {
  const data = useContext(DataContext);

  return (
    <img
      className={className}
      src={src}
      alt={`${data.artistName} - ${title} - ${year}`}
      loading="lazy"
    />
  );
};

export default Image;
