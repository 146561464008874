import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { normalizeQuery, sortedMediaName } from "../../config/normalize";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import Tag from "../Tag";
import "./worksListPreview.scss";

const WorksListPreview = ({ works, handleHighlightWorkID, resetHighlightWorkID }) => {
  const data = useContext(DataContext)

  return (
    <ul className="container__works--list--preview">
      {works
        .sort((a, b) => b.year - a.year)
        .map((work, i) => {
        const currentWork = data.works.find((item) => item.id === work.related_work_id)

        return (
          <li
            key={i}
            className="work--list--preview"
          >
            <Link
              to={`/work/${currentWork.id}=${normalizeQuery(currentWork.title)}`}
              onMouseEnter={data.maxWidth >= 768 ? () => handleHighlightWorkID(work.related_work_id) : undefined}
              onMouseLeave={resetHighlightWorkID}
            >
              <div className="work--preview">
                <CustomFont
                  tag="p"
                  className="small__font year"
                  content={currentWork.year}
                />
                <Tag data={data.tags.find((tag) => tag.id === currentWork.tag?.id)} />
                <CustomFont
                  tag="p"
                  className="small__font title ov--ELL"
                  content={currentWork.title}
                />
              </div>
              <div className="img--preview">
                <img
                  src={currentWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                />
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default WorksListPreview
