import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { sortedMediaName } from '../../config/normalize';
import DataContext from '../../config/dataContext';
import CustomFont from '../CustomFont';
import Image from '../Image';
import Tag from '../Tag';
import './thumbnailWork.scss';

const ThumbnailWork = ({ work }) => {
  const data = useContext(DataContext);

  return (
    <div
      className="container__thumbnail--work"
    >
      <div className="container__img">
        <Image
          src={work.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.url}
          title={work.title}
          year={work.year}
        />
      </div>
      <div className="container__infos">
        <div className="container__infos--primary">
          <CustomFont
            tag="p"
            className="small__font--mono t--UPPS"
            content={work.title}
          />
        </div>
        <div className="container__infos--secondary">
          <CustomFont
            tag="p"
            className="small__font--mono t--UPPS"
            content={work.year}
          />
          <Tag data={data.tags.find((tag) => tag.id === work.tag.id)} />
        </div>
      </div>
    </div>
  );
};

export default ThumbnailWork;
