import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { normalizeQuery, sortedMediaName } from "../../config/normalize";
import CustomFont from "../CustomFont";
import Image from '../Image';
import './bioExhibitions.scss';

const BioExhibitions = ({ data, title }) => {
  const d = useContext(DataContext)

  const harmonizeDateInt = (int) => {
    if (int <= 9) {
      return `0${int.toString()}`
    } else return int.toString()
  }

  return (
    <div className="wrapper__biography--exhibitions">
      <Helmet>
        {title && <title>{`${d.artistName} - ${title}`}</title>}        
      </Helmet>
      <ul className="container--exhibitions">
        {data
          .sort((a, b) => b.month?.id - a.month?.id)
          .sort((a, b) => b.year - a.year)
          .map((exhibition, i) =>
            <li
              key={i}
              className="item"
            >
              <div className="exhibition--item">
                {exhibition.year &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content={exhibition.year && exhibition.day != null || exhibition.month != null ? 'date:' : 'year:'}
                      />
                    </div>
                    <div className="content">
                      <p className="small__font">
                        {exhibition.day != null && <span>{harmonizeDateInt(exhibition.day)}.</span>}
                        {exhibition.month != null && 
                          <span>
                            {exhibition.day != null ?
                              `${harmonizeDateInt(exhibition.month.id)}.`
                            :
                              `${exhibition.month.name} `
                            }
                          </span>
                        }
                        <span>{exhibition.year}</span>
                      </p>
                    </div>
                  </div>
                }
                {/* {exhibition.period &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='period:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.period}
                      />
                    </div>
                  </div>
                } */}
                {exhibition.title &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='title:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.title}
                      />
                      {exhibition.subtitle &&
                        <CustomFont
                          tag='p'
                          className='small__font'
                          content={exhibition.subtitle}
                        />
                      }
                    </div>
                  </div>
                }
                {exhibition.author &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='author:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.author}
                      />
                    </div>
                  </div>
                }
                {exhibition.publisher &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='publisher:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.publisher}
                      />
                    </div>
                  </div>
                }
                {exhibition.location &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='location:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.location}
                      />
                    </div>
                  </div>
                }
                {exhibition.city &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='city:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.city}
                      />
                    </div>
                  </div>
                }
                {exhibition.source &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='source:'
                      />
                    </div>
                    <div className="content">
                      <CustomFont
                        tag='p'
                        className='small__font'
                        content={exhibition.source}
                      />
                    </div>
                  </div>
                }
                {exhibition.link &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='link:'
                      />
                    </div>
                    <div className="content">
                      <a href={exhibition.link} target="_blank" rel="noopener noreferrer">
                        <CustomFont
                          tag='p'
                          className='small__font link'
                          content={'More'}
                        />
                      </a>
                    </div>
                  </div>
                }
                {exhibition?.thumbnail &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='thumbnail:'
                      />
                    </div>
                    <div className="content">
                      {exhibition.link?.length >= 1 ? (
                        <a href={exhibition.link} target="_blank" rel="noopener noreferrer">
                          <Image
                            // src={exhibition.thumbnail?.formats?.large?.url}
                            src={exhibition.thumbnail?.url}
                            title={exhibition.title}
                            year={exhibition.year}
                            className='thumbnail'
                          />
                        </a>
                        ) : (
                          <Image
                          // src={exhibition.thumbnail?.formats?.large?.url}
                          src={exhibition.thumbnail?.url}
                          title={exhibition.title}
                          year={exhibition.year}
                          className='thumbnail'
                        />
                        )                      
                      }
                    </div>
                  </div>
                }
                {exhibition.exhibition_link?.medias?.length >= 1 &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='photos:'
                      />
                    </div>
                    <Link
                      to={`/work/${exhibition.exhibition_link.id}=${normalizeQuery(exhibition.exhibition_link.title)}`}
                      className="photo--gallery"
                    >
                      <img
                        src={exhibition.exhibition_link.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                      />
                      <img
                        src={exhibition.exhibition_link.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[1]?.formats.medium.url}
                      />
                      <img
                        src={exhibition.exhibition_link.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[2]?.formats.medium.url}
                      />
                      <img
                        src={exhibition.exhibition_link.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[3]?.formats.medium.url}
                      />
                    </Link>
                  </div>
                }
                {exhibition.medias?.length >= 1 &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='photos:'
                      />
                    </div>
                    {exhibition?.link.length >= 1 ? (
                      <a href={exhibition.link} target="_blank" rel="noopener noreferrer">
                        <div className="photo--gallery">
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[1]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[2]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[3]?.formats.medium.url}
                          />
                        </div>
                      </a>
                      ) : (
                        <div className="photo--gallery">
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[1]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[2]?.formats.medium.url}
                          />
                          <img
                            src={exhibition.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[3]?.formats.medium.url}
                          />
                        </div>
                      )                      
                    }
                  </div>
                }
                {exhibition.file &&
                  <div className="info--section">
                    <div className="label">
                      <CustomFont
                        tag='p'
                        className='small__font--mono t--UPPS c--GREY'
                        content='File:'
                      />
                    </div>
                    <div className="content">
                      <a
                        className="small__font--mono t--UPPS TAG"
                        href={exhibition.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                }
              </div>
            </li>
        )}
      </ul>
    </div>
  );
};

export default BioExhibitions;
