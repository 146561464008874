const normalizeQuery = (str) => {
  return str.replaceAll(' ', '-').toLowerCase();
}

const sortedMediaName = (str) => {
  return parseInt(str.split('-')[1])
}

export {
  normalizeQuery,
  sortedMediaName
}