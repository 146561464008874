import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import { Helmet } from 'react-helmet';
import BioExhibitions from '../../components/BioExhibitions'
// import CustomFont from '../../components/CustomFont';
// import DownloadBtn from '../../components/DownloadBtn';
// import ReactMarkdown from "react-markdown";
import './press.scss';

const Press = ({ presses }) => {
  const data = useContext(DataContext)
  // const [highlightPress, setHighlightPress] = useState({})

  // const theadBtn = [
  //   {name: "date", isActive: true},
  //   {name: "from", isActive: false},
  //   {name: "file", isActive: false},
  // ]

  // const harmonizeMonthID = (int) => {
  //   if (int <= 9) {
  //     return `0${int.toString()}`
  //   } else return int.toString()
  // }

  // const handleHighlight = (item) => {
  //   setHighlightPress(item)
  // }

  // const handleReset = () => {
  //   setHighlightPress({})
  // }
  
  return (
    <div className="wrapper__press">
      <Helmet>
        <title>{data.artistName} - Press</title>
      </Helmet>
      <div className="container__press">
        <BioExhibitions data={presses}/>
      </div>
    </div>
  );
};

export default Press;
