import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { normalizeQuery } from '../../config/normalize';
import DataContext from '../../config/dataContext';
import ThumbnailWork from '../ThumbnailWork';
import InformationsWork from '../InformationsWork';
import CustomFont from '../CustomFont';
import './selectedExhibitions.scss';

const SelectedExhibitions = ({ exhibitions }) => {
  const data = useContext(DataContext)
  const [selectedExhibitionId, setSelectedExhibitionsId] = useState();

  const handlePreview = (id) => {
    setSelectedExhibitionsId(id);
  }

  const resetPreview = () => {
    setSelectedExhibitionsId();
  }

  return (
    <div className="wrapper__selected--exhibitions">
      <div className="container__selected--exhibitions">
        <div className="container--left">
          <div className="container--fixed">
            {selectedExhibitionId ? (
              <InformationsWork work={data.works.find((work) => work.id === selectedExhibitionId)} isCompleted={false} />
            ) : (
              <>
                {/* <CustomFont
                  tag="p"
                  className="label small__font--mono t--UPPS c--GREY"
                  content="informations"
                /> */}
                <div className="label t--CAPS">
                  <CustomFont
                    tag="p"
                    className="big__font t--CAPS"
                    content="selected exhibitions"
                  />
                </div>
                <div className="CTA">
                  <Link
                    to="/index&type=1&sortBy=type"
                  >
                    <CustomFont
                      tag="p"
                      className="small__font link"
                      content="More exhibitions"
                    />
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="container--right">          
          <ul className="container__items">
            {exhibitions.map((exhibition) => 
              <li
                key={exhibition.id}
                className={selectedExhibitionId ? exhibition.id === selectedExhibitionId ? "item--hover active" : "item--hover no-active" : "item--hover" }
                onMouseLeave={resetPreview}
                onMouseEnter={data.maxWidth >= 768 ? () => handlePreview(exhibition.id) : undefined}
              >
                <Link
                  to={`/work/${exhibition.id}=${normalizeQuery(exhibition.title)}`}
                  className='link w--100'
                >
                  <ThumbnailWork work={exhibition} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectedExhibitions;
