import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { normalizeQuery, sortedMediaName } from '../../config/normalize';
import DataContext from '../../config/dataContext';
import CustomFont from '../CustomFont';
import LabelContent from '../LabelContent';
import Tag from '../Tag';
import './worksToDisplay.scss';

const WorksToDisplay = ({ works }) => {
  const data = useContext(DataContext);
  const [highlightWorkID, setHighlightWorkID] = useState();
  const [highlightWork, setHighlightWork] = useState({});
  const [isExhibition, setIsExhibition] = useState();
  const previewHeight = data.maxHeight - (document.getElementById('STICKY-FILTER')?.offsetHeight + 40 - 6);

  useEffect(() => {
    if (highlightWorkID != undefined) {
      setHighlightWork(data.works.find((work) => work.id === highlightWorkID))
    } else setHighlightWork({})
  }, [highlightWorkID])

  useEffect(() => {
    if (highlightWork.tag?.id === 8) {
      setIsExhibition(true)
    } else setIsExhibition(false)
  }, [highlightWork])

  const handleHighlightWorkID = (id) => {
    setHighlightWorkID(id)
  }

  const resetHighlightWorkID = () => {
    setHighlightWorkID()
  }

  return (
    <div className="wrapper__works">
      <ul className="container__works">
        {works.map((work) =>
          <li
            key={work.id}
            className="work--item"
            onMouseEnter={data.maxWidth >= 768 ? () => handleHighlightWorkID(work.id) : undefined}
            onMouseLeave={resetHighlightWorkID}
          >
            <Link to={`/work/${work.id}=${normalizeQuery(work.title)}`}>
              <div className="work--preview">
                <CustomFont
                  tag={'p'}
                  className={'small__font year'}
                  content={work.year}
                />
                <Tag data={data.tags.find((tag) => tag.id === work.tag?.id)} />
                <CustomFont
                  tag={'p'}
                  className={'small__font title ov--ELL'}
                  content={work.title}
                />
              </div>
              <div className="img--preview">
                <img
                  src={work.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                />
              </div>
            </Link>
          </li>
        )}
      </ul>
      {highlightWorkID &&
        <div className="container__fixed">
          <div
            className="container__preview"
            style={{
              height: previewHeight + "px",
              top: document.getElementById('STICKY-FILTER')?.offsetHeight + 20 - 6 + "px"
            }}
          >
            <div className="container__top--img">
              {isExhibition ?
                <>
                  <div className="container__grid--preview">
                    <img
                      src={highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.medium.url}
                    />
                    <img
                      src={highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[1]?.formats.medium.url}
                    />
                    <img
                      src={highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[2]?.formats.medium.url}
                    />
                    <img
                      src={highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[3]?.formats.medium.url}
                    />
                  </div>
                  {highlightWork?.medias.length >= 5 &&
                    <div className="count--item">
                      <CustomFont
                        tag="p"
                        className="small__font--mono t--UPPS c--GREY"
                        content={`(${highlightWork?.medias.length - 4} more)`}
                      />
                    </div>
                  }
                </>
              :
                highlightWork.medias &&
                  <img
                    src={highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats?.large?.url ?
                      highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.formats.large.url
                      :                      
                      highlightWork.medias.sort((a, b) => sortedMediaName(a.name) - sortedMediaName(b.name))[0]?.url
                    }
                    className="img--solo"
                  />
              }
            </div>
            <div className="container__bottom">
              {isExhibition ?
                highlightWork.location &&
                  <LabelContent
                    label="location"
                    content={highlightWork.location}
                  />
              :
                highlightWork.materials &&
                  <LabelContent
                    label="medium"
                    content={highlightWork.materials}
                  />
              }
              {isExhibition ?
                highlightWork.city &&
                  <LabelContent
                    label="city"
                    content={highlightWork.city}
                  />
              :
                highlightWork.dimensions &&
                  <LabelContent
                    label="dimensions"
                    content={`${highlightWork.dimensions} inches`}
                  />
              }
            </div>
          </div>
        </div> 
      }
    </div>
  );
};

export default WorksToDisplay;