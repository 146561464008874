import React from 'react';
// import Tag from '../Tag';
import CustomFont from '../CustomFont';
import './filterTHead.scss';

const FilterTHead = ({ data, handleSortURL }) => {

  return (
    <div className="container__filter--thead">
      <ul className="container__left">
          <CustomFont
            tag='p'
            className='sort--BY small__font--mono t--UPPS c--GREY'
            content='sort by:'
          />
        {data.map((el, i) =>
          <li
            key={i}
            className="filter--thead"
            onClick={() => handleSortURL(el.name)}
          >
            <p
              className={el.isActive ? "THEAD is--ACTIVE small__font--mono t--UPPS c--GREY" : "THEAD small__font--mono t--UPPS c--GREY"}
              // onClick={el.function}
            >
              {el.isActive ? `${el.name}⤵` : el.name}
            </p>
          </li>
        )}
      </ul>
      <div className="container__right">
        <CustomFont
          tag="p"
          className="small__font--mono t--UPPS c--GREY"
          content="preview"
        />
      </div>
    </div>
  );
};

export default FilterTHead;