import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import DataContext from '../../config/dataContext';
import CustomFont from '../../components/CustomFont';
import SearchInput from '../../components/SearchInput';
import WorksToDisplay from '../../components/WorksToDisplay';
import './search.scss';

const Search = ({ works }) => {
  const data = useContext(DataContext)
  const history = useHistory();
  const { queryParams } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [worksToDisplay, setWorksToDisplay] = useState([]);

  useEffect(() => {
    if (queryParams != undefined) {
      let arrSearchParams = queryParams.split('=')[1].split(' ');
      handleFiltering(setWorksToDisplay, arrSearchParams, works)
    } else setWorksToDisplay([])
  }, [queryParams])

  const handleFiltering = (setFunction, params, data) => {
    let filteredData = data.filter((item) => 
      // This can be upgraded to choose any values of the object 
      params.every((param) => JSON.stringify(item).toLowerCase().includes(param))
    )
    setFunction(filteredData
      .sort((a, b) => a.number - b.number)
      .sort((a, b) => b.year - a.year)
      .sort((a, b) => a.tag?.order - b.tag?.order)
    )
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  const handleSearch = (e) => {
    if (inputValue.length >= 3 && e.keyCode === 13) {
      history.push(`/search=${inputValue.toLowerCase()}`);
    }
  }

  const handleClickSearch = () => {
    if (inputValue.length >= 3) {
      history.push(`/search=${inputValue.toLowerCase()}`);
    }
  }

  const handleReset = () => {
    history.push('/search');
    setInputValue("");
    setWorksToDisplay([]);
  }

  return (
    <div className="wrapper__search">
      <Helmet>
        <title>{data.artistName} - Search</title>
      </Helmet>
      <div className="container__search">
        <SearchInput
          handleValue={handleValue}
          handleSearch={handleSearch}
          handleClickSearch={handleClickSearch}
          handleReset={handleReset}
          inputValue={inputValue}
        />
        {worksToDisplay.length >= 1 &&        
          <WorksToDisplay works={worksToDisplay} />
        }
        {queryParams != undefined && worksToDisplay.length <= 0 &&
          <div className="container__no--result">
            <CustomFont tag="p" className="small__font" content="No results." />
          </div>
        }
        {queryParams == undefined &&
          <div className="container__no--result">
            <CustomFont tag="p" className="small__font" content="Type anything." />
          </div>
        }
      </div>
    </div>
  );
};

export default Search;
