import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import Logo from "../../assets/Logo";
import DataContext from "../../config/dataContext";
import SelectedExhibitions from "../../components/SelectedExhibitions";
// import ContactCopyright from "../../components/ContactCopyright";
import './home.scss';

const Home = () => {
  const data = useContext(DataContext);
  const [selectedExhibitions, setSelectedExhibitions] = useState([]);

  useEffect(() => {
    if (data.isAllDataLoaded === true) {
      // let arr = [];
      // data.informations[0]?.selected_exhibitions.map((ex) => 
      //   arr.push(data.works.find((work) => work.id === ex.work_id))
      // )
      setSelectedExhibitions(data.works.filter((work) => work.tag.order === 1))
    }
  }, [data.isAllDataLoaded])

  return (
    <div className="wrapper__home">
      <Helmet>
        <title>{data.artistName}</title>
      </Helmet>
      <div className="container__home">
        <Logo />
        <SelectedExhibitions exhibitions={selectedExhibitions.sort((a, b) => b.year - a.year)} />
        {/* <ContactCopyright /> */}
      </div>
    </div>
  )
};

export default Home;