import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DataContext from '../../config/dataContext';
import CustomFont from '../CustomFont';
import Tag from '../Tag';
import LabelContent from '../LabelContent';
import RelatedWorks from '../RelatedWorks';
import './informationsWork.scss';
import { Link } from 'react-router-dom';

const InformationsWork = ({ work, isCompleted, handleHighlightWorkID, resetHighlightWorkID }) => {
  const data = useContext(DataContext)
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className="container--informations--work">
      {/* <div className="label--infos">
        <CustomFont
          tag="p"
          className="small__font--mono t--UPPS c--GREY"
          content="informations"
        />
      </div> */}
      <div className="main--infos">
        <div className="container--title">
          <CustomFont
            tag="h1"
            className="big__font"
            content={work.title}
          />
          {isCompleted &&
            <CustomFont
              tag="p"
              className="small__font--mono t--UPPS c--GREY link"
              content="back"
              onClick={goBack}
            />
          }
        </div>
        <div className="year--tag">
          <CustomFont
            tag="p"
            className="small__font year"
            content={work.year}
          />
          {work.tag &&
            <Link to={`/index&type=${work.tag.order}&sortBy=type`}>
              <Tag data={data.tags.find((tag) => tag.id === work.tag.id)} />
            </Link>
          }
        </div>
      </div>
      <div className="secondary--infos">
        {work.materials &&
          <LabelContent
            label="medium"
            content={work.materials}
          />
        }
        {work.dimensions &&
          <LabelContent
            label="dimensions"
            content={`${work.dimensions} inches`}
          />
        }
        {work.location &&
          <LabelContent
            label="location"
            content={work.location}
          />
        }
        {work.city &&
          <LabelContent
            label="city"
            content={work.city}
          />
        }
        {work.link &&
          <LabelContent
            label="external link"
            link={work.link}
            text="More"
          />
        }
      </div>
      {work.linked_works?.length >= 1 && isCompleted &&
        <RelatedWorks
          work={work}
          handleHighlightWorkID={handleHighlightWorkID}
          resetHighlightWorkID={resetHighlightWorkID}
        />
      }
    </div>
  );
};

export default InformationsWork;
