import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { motion } from 'framer-motion/dist/framer-motion';
import CustomFont from "../CustomFont";
import './startAnimation.scss';
import Logo from "../../assets/Logo";
import ProgressBar from "../ProgressBar";

const StartAnimation = ({}) => {
  const data = useContext(DataContext)

  return (
    <motion.div
      className="container__animation--start"
      style={{
        height: data.maxHeight,
        width: data.maxWidth + 1 +'px'
      }}
      initial={{
        x: 0
      }}
      animate={{
        x: data.isAllDataLoaded ? '-101vw' : 0
      }}
      transition={{
        ease: "easeInOut",
        default: { duration: 0.75 },
        delay: 1.5
      }}
    >
      <div className="container__center">
        <CustomFont
          tag="p"
          className="small__font--mono t--UPPS c--GREY"
          content="loading ruby sky stiler's website"
        />
        <ProgressBar />
      </div>
    </motion.div>
  );
};

export default StartAnimation;
